export default function FooterHome() {
    return (
        <>
            <div style={{ backgroundColor: "black", color: "white", display: "flex", justifyContent: "space-around" }} className="border-t-2 text-center p-3 mt-10">
                {/* <p>©2024</p>
            <p>Facebook</p>

            <p>Terms of Service</p>

            <p>Privacy Policy</p> */}


                {/* Footer */}
                Global Headquarters , 
                101 Redwood Shores Parkway,
                Redwood City, CA 94065
                USA.  

                (888) 976-9056
            </div>
        </>
    )
}