import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import valid from "card-validator";
import { isValidPhoneNumber } from "react-phone-number-input";

export function cn(...inputs) {
	return twMerge(clsx(inputs));
}

export const isValidEmail = email => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isValidCreditCard = cardNumber => {
	// Clean the input by removing spaces and non-digit characters
	const cleanedCardNumber = cardNumber.replace(/\D/g, "");

	console.log("cleanedCardNumber==", cleanedCardNumber);

	// Check if the card number is between 13 and 19 digits long
	if (cleanedCardNumber.length < 13 || cleanedCardNumber.length > 19) {
		return false;
	}

	// Use Luhn algorithm to validate the card number
	let sum = 0;
	let doubleUp = false;

	for (let i = cleanedCardNumber.length - 1; i >= 0; i--) {
		let digit = parseInt(cleanedCardNumber.charAt(i));

		if (doubleUp) {
			digit *= 2;
			if (digit > 9) {
				digit -= 9;
			}
		}

		sum += digit;
		doubleUp = !doubleUp;
	}

	return sum % 10 === 0;
};

export const detectCreditCardType = cardNumber => {
	// Clean the input by removing spaces and non-digit characters
	const cleanedCardNumber = cardNumber.replace(/\D/g, "");

	// Define regex patterns for common card types
	const patterns = {
		visa: /^4\d{12}(\d{3})?$/,
		mastercard: /^5[1-5]\d{14}$/,
		amex: /^3[47]\d{13}$/,
		discover: /^6(?:011|5[0-9]{2})\d{12}$/,
	};

	// Match the cleaned card number against known patterns
	for (const [type, pattern] of Object.entries(patterns)) {
		if (pattern.test(cleanedCardNumber)) {
			return type;
		}
	}

	return "unknown";
};

export const validateForm = data => {
	const newErrors = {};

	// Validate first name
	if (!data?.fname.trim()) {
		newErrors.fname = "First name is required";
	}

	// Validate last name
	if (!data?.lname.trim()) {
		newErrors.lname = "Last name is required";
	}

	// Validate last companyname
	if (!data?.companyName.trim()) {
		newErrors.companyName = "CompanyName name is required";
	}

	// Valiadtion crad
	if (!data?.cardNumber.trim()) {
		newErrors.cardNumber = "Card number is required";
	} else if (!validateCardNumber(data?.cardNumber)?.isValid) {
		newErrors.cardNumber = "Invalid card number";
		console.log("Invalid credit card number");
	}

	// Validate email
	if (!data?.email.trim()) {
		newErrors.email = "Email is required";
	} else if (!isValidEmail(data?.email)) {
		newErrors.email = "Invalid email address";
	}

	// Validate card expairation
	if (!data?.cardExpairDate.trim()) {
		newErrors.cardExpairDate = "Card expair date is required";
	} else if (!validateExpiryDate(data?.cardExpairDate).isValid) {
		newErrors.cardExpairDate = "Invalid expiration date";
	}

	//Validate post code
	if (!data?.postCode.trim()) {
		newErrors.postCode = "Post code is required";
	} else if (!validatePostalCode(data?.postCode)) {
		newErrors.postCode = "Invalid post code";
	}

	if (!data.securityKey.trim()) {
		newErrors.securityKey = "Security key code is required";
	} else if (!validateCVC(data.securityKey).isValid) {
		newErrors.securityKey = "Invalid security key";
	}

	if (!data.country.trim()) {
		newErrors.country = "Country is required";
	}
	if (!data.state.trim()) {
		newErrors.state = "Sate is required";
	}
	if (!data.city.trim()) {
		newErrors.city = "City is required";
	}
	if (!data.address1.trim()) {
		newErrors.address1 = "Address1 is required";
	}
	if (!data.phoneNumber.trim()) {
		newErrors.phoneNumber = "Phone number is required";
	} else if (!isValidPhoneNumber(data.phoneNumber)) {
		newErrors.phoneNumber = "Phone number is invalid";
	}

	if (!data?.billCycleDay) {
		newErrors.billCycleDay = "Billcycle method is required";
	}

	if (!data?.paymentMethod) {
		newErrors.paymentMethod = "Payment method is required";
	}

	if (!data?.cardType) {
		newErrors.cardType = "Card type is required";
	}
	return newErrors; // Return error obj
};

export const cardType = number => {
	const numberValidation = valid.number(number);

	return numberValidation.card.type;
};

export const validateCardNumber = number => {
	const numberValidation = valid.number(number);
	console.log(
		"saaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
		numberValidation.card.type
	);
	return {
		isValid: numberValidation.isValid,
		message: numberValidation.isValid ? "" : "Invalid card number",
	};
};

export const validateExpiryDate = expiry => {
	const expiryValidation = valid.expirationDate(expiry);
	return {
		isValid: expiryValidation.isValid,
		message: expiryValidation.isValid ? "" : "Invalid expiration date",
	};
};

export const validateCVC = cvc => {
	const cvcValidation = valid.cvv(cvc);
	return {
		isValid: cvcValidation.isValid,
		message: cvcValidation.isValid ? "" : "Invalid CVC",
	};
};

function validatePostalCode(postalCode) {
	postalCode = postalCode.trim();

	// Regular expression for basic alphanumeric postal codes
	const regex = /^[a-zA-Z0-9\s]*$/;

	// Check if the postal code matches the regular expression and length requirements
	return regex.test(postalCode);
}

export const getCurentDate = () => {
	const currentDate = new Date();

	const year = currentDate.getFullYear(); // Get the current year
	const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Get the current month (add 1 since January is 0)
	const day = String(currentDate.getDate()).padStart(2, "0"); // Get the current day

	const formattedDate = `${year}-${month}-${day}`;

	return { formattedDate, day };
};

console.log("getCurentDate==", getCurentDate());
