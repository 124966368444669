import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrencyType } from "../store/productSlice.js";

const CurrencyDropdown = () => {
	const [openDropDown, setOpenDropDown] = useState(false);
	const { currencyType } = useSelector(state => state.productPlans);
	const dispatch = useDispatch();

	const handleOpenDropDown = () => {
		setOpenDropDown(!openDropDown);
	};

	const handleSelectCurrency = currency => {
		dispatch(setCurrencyType(currency));
		setOpenDropDown(false);
	};

	return (
		<div className="relative inline-block text-left">
			<button
				onClick={handleOpenDropDown}
				id="dropdownDefaultButton"
				data-dropdown-toggle="dropdown"
				className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
				type="button"
			>
				{currencyType}{" "}
				<svg
					className="w-2.5 h-2.5 ml-3"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 10 6"
				>
					<path
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="m1 1 4 4 4-4"
					/>
				</svg>
			</button>

			{openDropDown && (
				<div
					id="dropdown"
					className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
				>
					<ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
						<li
							className="flex gap-2 p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
							onClick={() => handleSelectCurrency("INR")}
						>
							<img
								className="h-5 w-8"
								src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
								alt="Indian Rupee"
							/>
							<span>Indian Rupee (INR)</span>
						</li>
						<li
							className="flex gap-2 p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
							onClick={() => handleSelectCurrency("USD")}
						>
							<img
								className="h-5 w-8"
								src="https://cdn.britannica.com/73/4473-050-0D875725/Grand-Union-Flag-January-1-1776.jpg"
								alt="US Dollar"
							/>
							<span>US Dollar (USD)</span>
						</li>
						<li
							className="flex gap-2 p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
							onClick={() => handleSelectCurrency("EUR")}
						>
							<img
								className="h-5 w-8"
								src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Flag_of_Europe.svg/255px-Flag_of_Europe.svg.png"
								alt="Euro"
							/>
							<span>Euro (EUR)</span>
						</li>
						<li
							className="flex gap-2 p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
							onClick={() => handleSelectCurrency("GBP")}
						>
							<img
								className="h-5 w-8"
								src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1280px-Flag_of_the_United_Kingdom.svg.png"
								alt="British Pound Sterling"
							/>
							<span>British Pound Sterling (GBP)</span>
						</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default CurrencyDropdown;
