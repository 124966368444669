export const productPlan = {
	productRatePlans: [
		{
			id: "8a8aa1028e41a13b018e5146dc1c5c51",
			status: "Active",
			name: "Individual",
			description: "IT",
			effectiveStartDate: "2010-01-01",
			effectiveEndDate: "2100-01-01",
			externalIdSourceSystem: "",
			externallyManagedPlanIds: [],
			productRatePlanCharges: [
				{
					id: "8a8aa1028e41a13b018e51481d145d24",
					name: "Free",
					type: "Recurring",
					model: "FlatFee",
					uom: null,
					pricingSummary: ["USD0"],
					pricing: [
						{
							currency: "USD",
							price: 0.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
					],
					defaultQuantity: null,
					applyDiscountTo: null,
					discountLevel: null,
					discountClass: null,
					productDiscountApplyDetails: [],
					endDateCondition: "Subscription_End",
					upToPeriods: null,
					upToPeriodsType: null,
					billingDay: "DefaultFromCustomer",
					listPriceBase: "Per_Billing_Period",
					specificListPriceBase: null,
					billingTiming: "IN_ADVANCE",
					ratingGroup: null,
					billingPeriod: "Month",
					billingPeriodAlignment: "AlignToCharge",
					specificBillingPeriod: null,
					smoothingModel: null,
					numberOfPeriods: null,
					overageCalculationOption: null,
					overageUnusedUnitsCreditOption: null,
					unusedIncludedUnitPrice: null,
					usageRecordRatingOption: null,
					priceChangeOption: null,
					priceIncreasePercentage: null,
					useTenantDefaultForPriceChange: true,
					taxable: false,
					taxCode: "",
					taxMode: null,
					isPrepaid: false,
					prepaidOperationType: null,
					prepaidUom: null,
					prepaidQuantity: null,
					prepaidTotalQuantity: null,
					validityPeriodType: "SUBSCRIPTION_TERM",
					drawdownRate: null,
					drawdownUom: null,
					creditOption: "TimeBased",
					isRollover: false,
					rolloverPeriods: 0,
					rolloverPeriodLength: 0,
					rolloverApply: "ApplyLast",
					prorationOption: null,
					chargeFunction: null,
					POBCRITERIA__c: "BK-OT-RATABLE",
					RightToBill__c: "N",
					Width__c: null,
					ProductFamily__c: "Subscription",
					Rim__c: null,
					LoadSpeed__c: null,
					ProductCategory__c: "Subscription",
					CVELIGIBLE__c: "Y",
					triggerEvent: "ContractEffective",
					description: "",
					revRecCode: null,
					revRecTriggerCondition: null,
					revenueRecognitionRuleName: "Recognize upon invoicing",
					useDiscountSpecificAccountingCode: null,
					excludeItemBookingFromRevenueAccounting: false,
					excludeItemBillingFromRevenueAccounting: false,
					financeInformation: {
						deferredRevenueAccountingCode: "Subscription Revenue",
						deferredRevenueAccountingCodeType: "SalesRevenue",
						recognizedRevenueAccountingCode: "Subscription Revenue",
						recognizedRevenueAccountingCodeType: "SalesRevenue",
						accountsReceivableAccountingCode: "Accounts Receivable",
						accountsReceivableAccountingCodeType: "AccountsReceivable",
						contractAssetAccountingCode: null,
						contractAssetAccountingCodeType: null,
						contractLiabilityAccountingCode: null,
						contractLiabilityAccountingCodeType: null,
						adjustmentLiabilityAccountingCode: null,
						adjustmentLiabilityAccountingCodeType: null,
						unbilledReceivablesAccountingCode: null,
						unbilledReceivablesAccountingCodeType: null,
						adjustmentRevenueAccountingCode: null,
						adjustmentRevenueAccountingCodeType: null,
						contractRecognizedRevenueAccountingCode: null,
						contractRecognizedRevenueAccountingCodeType: null,
					},
					deliverySchedule: null,
					reflectDiscountInNetAmount: false,
					isStackedDiscount: false,
					productRatePlanChargeNumber: "PRPC-00000340",
					isCommitted: false,
					commitmentType: null,
				},
			],
			productRatePlanNumber: "PRP-00000222",
		},
		{
			id: "8a8aa1a68e41a135018e51483e3b0e68",
			status: "Active",
			name: "Personal Pro Monthly",
			description: "IT",
			effectiveStartDate: "2010-01-01",
			effectiveEndDate: "2100-01-01",
			externalIdSourceSystem: "",
			externallyManagedPlanIds: [],
			productRatePlanCharges: [
				{
					id: "8a8aa1a68e41a135018e51483e670e6a",
					name: "Personal Pro",
					type: "Recurring",
					model: "FlatFee",
					uom: null,
					pricingSummary: ["USD1050"],
					pricing: [
						{
							currency: "USD",
							price: 1050.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
					],
					defaultQuantity: null,
					applyDiscountTo: null,
					discountLevel: null,
					discountClass: null,
					productDiscountApplyDetails: [],
					endDateCondition: "Subscription_End",
					upToPeriods: null,
					upToPeriodsType: null,
					billingDay: "DefaultFromCustomer",
					listPriceBase: "Per_Billing_Period",
					specificListPriceBase: null,
					billingTiming: "IN_ADVANCE",
					ratingGroup: null,
					billingPeriod: "Month",
					billingPeriodAlignment: "AlignToCharge",
					specificBillingPeriod: null,
					smoothingModel: null,
					numberOfPeriods: null,
					overageCalculationOption: null,
					overageUnusedUnitsCreditOption: null,
					unusedIncludedUnitPrice: null,
					usageRecordRatingOption: null,
					priceChangeOption: null,
					priceIncreasePercentage: null,
					useTenantDefaultForPriceChange: true,
					taxable: false,
					taxCode: "",
					taxMode: null,
					isPrepaid: false,
					prepaidOperationType: null,
					prepaidUom: null,
					prepaidQuantity: null,
					prepaidTotalQuantity: null,
					validityPeriodType: "SUBSCRIPTION_TERM",
					drawdownRate: null,
					drawdownUom: null,
					creditOption: "TimeBased",
					isRollover: false,
					rolloverPeriods: 0,
					rolloverPeriodLength: 0,
					rolloverApply: "ApplyLast",
					prorationOption: null,
					chargeFunction: null,
					POBCRITERIA__c: "BK-OT-RATABLE",
					RightToBill__c: "N",
					Width__c: null,
					ProductFamily__c: "Subscription",
					Rim__c: null,
					LoadSpeed__c: null,
					ProductCategory__c: "Subscription",
					CVELIGIBLE__c: "Y",
					triggerEvent: "ContractEffective",
					description: "",
					revRecCode: null,
					revRecTriggerCondition: null,
					revenueRecognitionRuleName: "Recognize upon invoicing",
					useDiscountSpecificAccountingCode: null,
					excludeItemBookingFromRevenueAccounting: false,
					excludeItemBillingFromRevenueAccounting: false,
					financeInformation: {
						deferredRevenueAccountingCode: "Subscription Revenue",
						deferredRevenueAccountingCodeType: "SalesRevenue",
						recognizedRevenueAccountingCode: "Subscription Revenue",
						recognizedRevenueAccountingCodeType: "SalesRevenue",
						accountsReceivableAccountingCode: "Accounts Receivable",
						accountsReceivableAccountingCodeType: "AccountsReceivable",
						contractAssetAccountingCode: null,
						contractAssetAccountingCodeType: null,
						contractLiabilityAccountingCode: null,
						contractLiabilityAccountingCodeType: null,
						adjustmentLiabilityAccountingCode: null,
						adjustmentLiabilityAccountingCodeType: null,
						unbilledReceivablesAccountingCode: null,
						unbilledReceivablesAccountingCodeType: null,
						adjustmentRevenueAccountingCode: null,
						adjustmentRevenueAccountingCodeType: null,
						contractRecognizedRevenueAccountingCode: null,
						contractRecognizedRevenueAccountingCodeType: null,
					},
					deliverySchedule: null,
					reflectDiscountInNetAmount: false,
					isStackedDiscount: false,
					productRatePlanChargeNumber: null,
					isCommitted: false,
					commitmentType: null,
				},
			],
			productRatePlanNumber: null,
		},
		{
			id: "8a8aa2ca8e41b3c8018e5149e4617409",
			status: "Active",
			name: "Business Starter",
			description: "IT",
			effectiveStartDate: "2010-01-01",
			effectiveEndDate: "2100-01-01",
			externalIdSourceSystem: "",
			externallyManagedPlanIds: [],
			productRatePlanCharges: [
				{
					id: "8a8aa2ca8e41b3c8018e5149e485740b",
					name: "Business Starter",
					type: "Recurring",
					model: "FlatFee",
					uom: null,
					pricingSummary: ["USD1750", "EUR1230", "GBP1420", "AUD1520"],
					pricing: [
						{
							currency: "USD",
							price: 1750.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "EUR",
							price: 1230.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "GBP",
							price: 1420.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "AUD",
							price: 1520.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
					],
					defaultQuantity: null,
					applyDiscountTo: null,
					discountLevel: null,
					discountClass: null,
					productDiscountApplyDetails: [],
					endDateCondition: "Subscription_End",
					upToPeriods: null,
					upToPeriodsType: null,
					billingDay: "DefaultFromCustomer",
					listPriceBase: "Per_Billing_Period",
					specificListPriceBase: null,
					billingTiming: "IN_ADVANCE",
					ratingGroup: null,
					billingPeriod: "Month",
					billingPeriodAlignment: "AlignToCharge",
					specificBillingPeriod: null,
					smoothingModel: null,
					numberOfPeriods: null,
					overageCalculationOption: null,
					overageUnusedUnitsCreditOption: null,
					unusedIncludedUnitPrice: null,
					usageRecordRatingOption: null,
					priceChangeOption: null,
					priceIncreasePercentage: null,
					useTenantDefaultForPriceChange: true,
					taxable: false,
					taxCode: "",
					taxMode: null,
					isPrepaid: false,
					prepaidOperationType: null,
					prepaidUom: null,
					prepaidQuantity: null,
					prepaidTotalQuantity: null,
					validityPeriodType: "SUBSCRIPTION_TERM",
					drawdownRate: null,
					drawdownUom: null,
					creditOption: "TimeBased",
					isRollover: false,
					rolloverPeriods: 0,
					rolloverPeriodLength: 0,
					rolloverApply: "ApplyLast",
					prorationOption: null,
					chargeFunction: null,
					POBCRITERIA__c: "BK-OT-RATABLE",
					RightToBill__c: "N",
					Width__c: null,
					ProductFamily__c: "Subscription",
					Rim__c: null,
					LoadSpeed__c: null,
					ProductCategory__c: "Subscription",
					CVELIGIBLE__c: "Y",
					triggerEvent: "ContractEffective",
					description: "",
					revRecCode: null,
					revRecTriggerCondition: null,
					revenueRecognitionRuleName: "Recognize upon invoicing",
					useDiscountSpecificAccountingCode: null,
					excludeItemBookingFromRevenueAccounting: false,
					excludeItemBillingFromRevenueAccounting: false,
					financeInformation: {
						deferredRevenueAccountingCode: "Subscription Revenue",
						deferredRevenueAccountingCodeType: "SalesRevenue",
						recognizedRevenueAccountingCode: "Subscription Revenue",
						recognizedRevenueAccountingCodeType: "SalesRevenue",
						accountsReceivableAccountingCode: "Accounts Receivable",
						accountsReceivableAccountingCodeType: "AccountsReceivable",
						contractAssetAccountingCode: null,
						contractAssetAccountingCodeType: null,
						contractLiabilityAccountingCode: null,
						contractLiabilityAccountingCodeType: null,
						adjustmentLiabilityAccountingCode: null,
						adjustmentLiabilityAccountingCodeType: null,
						unbilledReceivablesAccountingCode: null,
						unbilledReceivablesAccountingCodeType: null,
						adjustmentRevenueAccountingCode: null,
						adjustmentRevenueAccountingCodeType: null,
						contractRecognizedRevenueAccountingCode: null,
						contractRecognizedRevenueAccountingCodeType: null,
					},
					deliverySchedule: null,
					reflectDiscountInNetAmount: false,
					isStackedDiscount: false,
					productRatePlanChargeNumber: null,
					isCommitted: false,
					commitmentType: null,
				},
			],
			productRatePlanNumber: null,
		},
		{
			id: "8a8aa1a68e79b0d7018e7ebc7e952925",
			status: "Active",
			name: "Business",
			description: "B",
			effectiveStartDate: "2010-01-01",
			effectiveEndDate: "2100-01-01",
			externalIdSourceSystem: "",
			externallyManagedPlanIds: [],
			productRatePlanCharges: [
				{
					id: "8a8aa1a68e79b0d7018e7ebc7ebe2927",
					name: "Business",
					type: "Recurring",
					model: "FlatFee",
					uom: null,
					pricingSummary: ["USD11000", "EUR1230", "GBP1420", "AUD1520"],
					pricing: [
						{
							currency: "USD",
							price: 11000.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "EUR",
							price: 1230.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "GBP",
							price: 1420.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "AUD",
							price: 1520.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
					],
					defaultQuantity: null,
					applyDiscountTo: null,
					discountLevel: null,
					discountClass: null,
					productDiscountApplyDetails: [],
					endDateCondition: "Subscription_End",
					upToPeriods: null,
					upToPeriodsType: null,
					billingDay: "DefaultFromCustomer",
					listPriceBase: "Per_Billing_Period",
					specificListPriceBase: null,
					billingTiming: "IN_ADVANCE",
					ratingGroup: null,
					billingPeriod: "Annual",
					billingPeriodAlignment: "AlignToCharge",
					specificBillingPeriod: null,
					smoothingModel: null,
					numberOfPeriods: null,
					overageCalculationOption: null,
					overageUnusedUnitsCreditOption: null,
					unusedIncludedUnitPrice: null,
					usageRecordRatingOption: null,
					priceChangeOption: null,
					priceIncreasePercentage: null,
					useTenantDefaultForPriceChange: true,
					taxable: false,
					taxCode: "",
					taxMode: null,
					isPrepaid: false,
					prepaidOperationType: null,
					prepaidUom: null,
					prepaidQuantity: null,
					prepaidTotalQuantity: null,
					validityPeriodType: "SUBSCRIPTION_TERM",
					drawdownRate: null,
					drawdownUom: null,
					creditOption: "TimeBased",
					isRollover: false,
					rolloverPeriods: 0,
					rolloverPeriodLength: 0,
					rolloverApply: "ApplyLast",
					prorationOption: null,
					chargeFunction: null,
					POBCRITERIA__c: "BK-OT-RATABLE",
					RightToBill__c: "N",
					Width__c: null,
					ProductFamily__c: "Subscription",
					Rim__c: null,
					LoadSpeed__c: null,
					ProductCategory__c: "Subscription",
					CVELIGIBLE__c: "Y",
					triggerEvent: "ContractEffective",
					description: "",
					revRecCode: null,
					revRecTriggerCondition: null,
					revenueRecognitionRuleName: "Recognize upon invoicing",
					useDiscountSpecificAccountingCode: null,
					excludeItemBookingFromRevenueAccounting: false,
					excludeItemBillingFromRevenueAccounting: false,
					financeInformation: {
						deferredRevenueAccountingCode: "Subscription Revenue",
						deferredRevenueAccountingCodeType: "SalesRevenue",
						recognizedRevenueAccountingCode: "Subscription Revenue",
						recognizedRevenueAccountingCodeType: "SalesRevenue",
						accountsReceivableAccountingCode: "Accounts Receivable",
						accountsReceivableAccountingCodeType: "AccountsReceivable",
						contractAssetAccountingCode: null,
						contractAssetAccountingCodeType: null,
						contractLiabilityAccountingCode: null,
						contractLiabilityAccountingCodeType: null,
						adjustmentLiabilityAccountingCode: null,
						adjustmentLiabilityAccountingCodeType: null,
						unbilledReceivablesAccountingCode: null,
						unbilledReceivablesAccountingCodeType: null,
						adjustmentRevenueAccountingCode: null,
						adjustmentRevenueAccountingCodeType: null,
						contractRecognizedRevenueAccountingCode: null,
						contractRecognizedRevenueAccountingCodeType: null,
					},
					deliverySchedule: null,
					reflectDiscountInNetAmount: false,
					isStackedDiscount: false,
					productRatePlanChargeNumber: null,
					isCommitted: false,
					commitmentType: null,
				},
			],
			productRatePlanNumber: null,
		},
		{
			id: "8a8aa2028e79c444018e7ebc79950c4c",
			status: "Active",
			name: "Business Plus",
			description: "B",
			effectiveStartDate: "2010-01-01",
			effectiveEndDate: "2100-01-01",
			externalIdSourceSystem: "",
			externallyManagedPlanIds: [],
			productRatePlanCharges: [
				{
					id: "8a8aa2028e79c444018e7ebc79b90c4e",
					name: "Business Plus",
					type: "Recurring",
					model: "FlatFee",
					uom: null,
					pricingSummary: ["USD12000", "EUR1230", "GBP1420", "AUD1520"],
					pricing: [
						{
							currency: "USD",
							price: 12000.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "EUR",
							price: 1230.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "GBP",
							price: 1420.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "AUD",
							price: 1520.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
					],
					defaultQuantity: null,
					applyDiscountTo: null,
					discountLevel: null,
					discountClass: null,
					productDiscountApplyDetails: [],
					endDateCondition: "Subscription_End",
					upToPeriods: null,
					upToPeriodsType: null,
					billingDay: "DefaultFromCustomer",
					listPriceBase: "Per_Billing_Period",
					specificListPriceBase: null,
					billingTiming: "IN_ADVANCE",
					ratingGroup: null,
					billingPeriod: "Annual",
					billingPeriodAlignment: "AlignToCharge",
					specificBillingPeriod: null,
					smoothingModel: null,
					numberOfPeriods: null,
					overageCalculationOption: null,
					overageUnusedUnitsCreditOption: null,
					unusedIncludedUnitPrice: null,
					usageRecordRatingOption: null,
					priceChangeOption: null,
					priceIncreasePercentage: null,
					useTenantDefaultForPriceChange: true,
					taxable: false,
					taxCode: "",
					taxMode: null,
					isPrepaid: false,
					prepaidOperationType: null,
					prepaidUom: null,
					prepaidQuantity: null,
					prepaidTotalQuantity: null,
					validityPeriodType: "SUBSCRIPTION_TERM",
					drawdownRate: null,
					drawdownUom: null,
					creditOption: "TimeBased",
					isRollover: false,
					rolloverPeriods: 0,
					rolloverPeriodLength: 0,
					rolloverApply: "ApplyLast",
					prorationOption: null,
					chargeFunction: null,
					POBCRITERIA__c: "BK-OT-RATABLE",
					RightToBill__c: "N",
					Width__c: null,
					ProductFamily__c: "Subscription",
					Rim__c: null,
					LoadSpeed__c: null,
					ProductCategory__c: "Subscription",
					CVELIGIBLE__c: "Y",
					triggerEvent: "ContractEffective",
					description: "",
					revRecCode: null,
					revRecTriggerCondition: null,
					revenueRecognitionRuleName: "Recognize upon invoicing",
					useDiscountSpecificAccountingCode: null,
					excludeItemBookingFromRevenueAccounting: false,
					excludeItemBillingFromRevenueAccounting: false,
					financeInformation: {
						deferredRevenueAccountingCode: "Subscription Revenue",
						deferredRevenueAccountingCodeType: "SalesRevenue",
						recognizedRevenueAccountingCode: "Subscription Revenue",
						recognizedRevenueAccountingCodeType: "SalesRevenue",
						accountsReceivableAccountingCode: "Accounts Receivable",
						accountsReceivableAccountingCodeType: "AccountsReceivable",
						contractAssetAccountingCode: null,
						contractAssetAccountingCodeType: null,
						contractLiabilityAccountingCode: null,
						contractLiabilityAccountingCodeType: null,
						adjustmentLiabilityAccountingCode: null,
						adjustmentLiabilityAccountingCodeType: null,
						unbilledReceivablesAccountingCode: null,
						unbilledReceivablesAccountingCodeType: null,
						adjustmentRevenueAccountingCode: null,
						adjustmentRevenueAccountingCodeType: null,
						contractRecognizedRevenueAccountingCode: null,
						contractRecognizedRevenueAccountingCodeType: null,
					},
					deliverySchedule: null,
					reflectDiscountInNetAmount: false,
					isStackedDiscount: false,
					productRatePlanChargeNumber: null,
					isCommitted: false,
					commitmentType: null,
				},
			],
			productRatePlanNumber: null,
		},
		{
			id: "8a8aa2028e79c444018e7ebc67dd0c38",
			status: "Active",
			name: "Enterprise",
			description: "B",
			effectiveStartDate: "2010-01-01",
			effectiveEndDate: "2100-01-01",
			externalIdSourceSystem: "",
			externallyManagedPlanIds: [],
			productRatePlanCharges: [
				{
					id: "8a8aa2028e79c444018e7ebc68210c3a",
					name: "Enterprise",
					type: "Recurring",
					model: "FlatFee",
					uom: null,
					pricingSummary: ["USD16000", "EUR1230", "GBP1420", "AUD1520"],
					pricing: [
						{
							currency: "USD",
							price: 16000.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "EUR",
							price: 1230.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "GBP",
							price: 1420.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "AUD",
							price: 1520.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
					],
					defaultQuantity: null,
					applyDiscountTo: null,
					discountLevel: null,
					discountClass: null,
					productDiscountApplyDetails: [],
					endDateCondition: "Subscription_End",
					upToPeriods: null,
					upToPeriodsType: null,
					billingDay: "DefaultFromCustomer",
					listPriceBase: "Per_Billing_Period",
					specificListPriceBase: null,
					billingTiming: "IN_ADVANCE",
					ratingGroup: null,
					billingPeriod: "Annual",
					billingPeriodAlignment: "AlignToCharge",
					specificBillingPeriod: null,
					smoothingModel: null,
					numberOfPeriods: null,
					overageCalculationOption: null,
					overageUnusedUnitsCreditOption: null,
					unusedIncludedUnitPrice: null,
					usageRecordRatingOption: null,
					priceChangeOption: null,
					priceIncreasePercentage: null,
					useTenantDefaultForPriceChange: true,
					taxable: false,
					taxCode: "",
					taxMode: null,
					isPrepaid: false,
					prepaidOperationType: null,
					prepaidUom: null,
					prepaidQuantity: null,
					prepaidTotalQuantity: null,
					validityPeriodType: "SUBSCRIPTION_TERM",
					drawdownRate: null,
					drawdownUom: null,
					creditOption: "TimeBased",
					isRollover: false,
					rolloverPeriods: 0,
					rolloverPeriodLength: 0,
					rolloverApply: "ApplyLast",
					prorationOption: null,
					chargeFunction: null,
					POBCRITERIA__c: "BK-OT-RATABLE",
					RightToBill__c: "N",
					Width__c: null,
					ProductFamily__c: "Subscription",
					Rim__c: null,
					LoadSpeed__c: null,
					ProductCategory__c: "Subscription",
					CVELIGIBLE__c: "Y",
					triggerEvent: "ContractEffective",
					description: "",
					revRecCode: null,
					revRecTriggerCondition: null,
					revenueRecognitionRuleName: "Recognize upon invoicing",
					useDiscountSpecificAccountingCode: null,
					excludeItemBookingFromRevenueAccounting: false,
					excludeItemBillingFromRevenueAccounting: false,
					financeInformation: {
						deferredRevenueAccountingCode: "Subscription Revenue",
						deferredRevenueAccountingCodeType: "SalesRevenue",
						recognizedRevenueAccountingCode: "Subscription Revenue",
						recognizedRevenueAccountingCodeType: "SalesRevenue",
						accountsReceivableAccountingCode: "Accounts Receivable",
						accountsReceivableAccountingCodeType: "AccountsReceivable",
						contractAssetAccountingCode: null,
						contractAssetAccountingCodeType: null,
						contractLiabilityAccountingCode: null,
						contractLiabilityAccountingCodeType: null,
						adjustmentLiabilityAccountingCode: null,
						adjustmentLiabilityAccountingCodeType: null,
						unbilledReceivablesAccountingCode: null,
						unbilledReceivablesAccountingCodeType: null,
						adjustmentRevenueAccountingCode: null,
						adjustmentRevenueAccountingCodeType: null,
						contractRecognizedRevenueAccountingCode: null,
						contractRecognizedRevenueAccountingCodeType: null,
					},
					deliverySchedule: null,
					reflectDiscountInNetAmount: false,
					isStackedDiscount: false,
					productRatePlanChargeNumber: null,
					isCommitted: false,
					commitmentType: null,
				},
			],
			productRatePlanNumber: null,
		},
		{
			id: "8a8aa1ee8e79b0c3018e7ebc622f5ab8",
			status: "Active",
			name: "Enterprise Plus",
			description: "B",
			effectiveStartDate: "2010-01-01",
			effectiveEndDate: "2100-01-01",
			externalIdSourceSystem: "",
			externallyManagedPlanIds: [],
			productRatePlanCharges: [
				{
					id: "8a8aa1ee8e79b0c3018e7ebc62805abc",
					name: "Enterprise Plus",
					type: "Recurring",
					model: "FlatFee",
					uom: null,
					pricingSummary: ["USD18000", "EUR1230", "GBP1420", "AUD1520"],
					pricing: [
						{
							currency: "USD",
							price: 18000.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "EUR",
							price: 1230.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "GBP",
							price: 1420.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
						{
							currency: "AUD",
							price: 1520.0,
							tiers: null,
							includedUnits: null,
							overagePrice: null,
							discountPercentage: null,
							discountAmount: null,
						},
					],
					defaultQuantity: null,
					applyDiscountTo: null,
					discountLevel: null,
					discountClass: null,
					productDiscountApplyDetails: [],
					endDateCondition: "Subscription_End",
					upToPeriods: null,
					upToPeriodsType: null,
					billingDay: "DefaultFromCustomer",
					listPriceBase: "Per_Billing_Period",
					specificListPriceBase: null,
					billingTiming: "IN_ADVANCE",
					ratingGroup: null,
					billingPeriod: "Annual",
					billingPeriodAlignment: "AlignToCharge",
					specificBillingPeriod: null,
					smoothingModel: null,
					numberOfPeriods: null,
					overageCalculationOption: null,
					overageUnusedUnitsCreditOption: null,
					unusedIncludedUnitPrice: null,
					usageRecordRatingOption: null,
					priceChangeOption: null,
					priceIncreasePercentage: null,
					useTenantDefaultForPriceChange: true,
					taxable: false,
					taxCode: "",
					taxMode: null,
					isPrepaid: false,
					prepaidOperationType: null,
					prepaidUom: null,
					prepaidQuantity: null,
					prepaidTotalQuantity: null,
					validityPeriodType: "SUBSCRIPTION_TERM",
					drawdownRate: null,
					drawdownUom: null,
					creditOption: "TimeBased",
					isRollover: false,
					rolloverPeriods: 0,
					rolloverPeriodLength: 0,
					rolloverApply: "ApplyLast",
					prorationOption: null,
					chargeFunction: null,
					POBCRITERIA__c: "BK-OT-RATABLE",
					RightToBill__c: "N",
					Width__c: null,
					ProductFamily__c: "Subscription",
					Rim__c: null,
					LoadSpeed__c: null,
					ProductCategory__c: "Subscription",
					CVELIGIBLE__c: "Y",
					triggerEvent: "ContractEffective",
					description: "",
					revRecCode: null,
					revRecTriggerCondition: null,
					revenueRecognitionRuleName: "Recognize upon invoicing",
					useDiscountSpecificAccountingCode: null,
					excludeItemBookingFromRevenueAccounting: false,
					excludeItemBillingFromRevenueAccounting: false,
					financeInformation: {
						deferredRevenueAccountingCode: "Subscription Revenue",
						deferredRevenueAccountingCodeType: "SalesRevenue",
						recognizedRevenueAccountingCode: "Subscription Revenue",
						recognizedRevenueAccountingCodeType: "SalesRevenue",
						accountsReceivableAccountingCode: "Accounts Receivable",
						accountsReceivableAccountingCodeType: "AccountsReceivable",
						contractAssetAccountingCode: null,
						contractAssetAccountingCodeType: null,
						contractLiabilityAccountingCode: null,
						contractLiabilityAccountingCodeType: null,
						adjustmentLiabilityAccountingCode: null,
						adjustmentLiabilityAccountingCodeType: null,
						unbilledReceivablesAccountingCode: null,
						unbilledReceivablesAccountingCodeType: null,
						adjustmentRevenueAccountingCode: null,
						adjustmentRevenueAccountingCodeType: null,
						contractRecognizedRevenueAccountingCode: null,
						contractRecognizedRevenueAccountingCodeType: null,
					},
					deliverySchedule: null,
					reflectDiscountInNetAmount: false,
					isStackedDiscount: false,
					productRatePlanChargeNumber: null,
					isCommitted: false,
					commitmentType: null,
				},
			],
			productRatePlanNumber: null,
		},
	],
	success: true,
};

const body = {
	description: "This is a description for the Order.",
	orderDate: "2024-03-20", //Today’s Date
	newAccount: {
		name: "Demospot 1", //First Name + Last Name from the CheckOut Page
		currency: "USD", //Currency Mentioned at the Top Right Currency Drop down on Pricing Page
		billCycleDay: "1", //Today’s Date, only DD part e.g 23rd March 2024 is the date, we will only consider 23
		billToContact: {
			firstName: "Kishore", //First Name from the CheckOut Page
			lastName: "kumar ", //Last Name from the CheckOut Page
			address1: "Redwoodcity",
			address2: "test",
			city: "Redwood",
			country: "United States", //Country from the CheckOut Page
			mobilePhone: "9889987654",
			state: "California",
			postalCode: 90801, //Postal Code from the CheckOut Page
		},
		paymentGateway: "Test Gateway", //Payment Gateway Field from the ADMIN Page
		paymentMethod: {
			type: "CreditCard",
			cardHolderInfo: {
				cardHolderName: "Kishore", //First Name + Last Name from the CheckOut Page
			},
			cardNumber: "1111222233334444", //Credit Card from the CheckOut Page
			cardType: "Visa",
			expirationMonth: "12", // MM out of “MM/YY” from the CheckOut Page
			expirationYear: "2034", //YYYY out of “MM/YY” from the CheckOut Page
			securityCode: "111", //Security Code from the CheckOut Page
		},
		soldToContact: {
			firstName: "Kishore", //First Name from the CheckOut Page
			lastName: "kumar ", //Last Name from the CheckOut Page
			address1: "Redwoodcity",
			address2: "",
			city: "Redwood",
			country: "United States", //Country from the CheckOut Page
			mobilePhone: "9876544323",
			state: "California",
			postalCode: 90801, //Postal Code from the CheckOut Page
		},
	},
	processingOptions: {
		billingOptions: {
			generateDraftInvoice: "false",
			targetDate: "2024-03-20", //Today’s Date. ie. Run Date
		},
		collectPayment: true,
		runBilling: true,
	},
	subscriptions: [
		{
			orderActions: [
				{
					createSubscription: {
						subscribeToRatePlans: [
							{
								chargeOverrides: [
									{
										productRatePlanChargeId: "8a8aa04d7f3a5d78017f42a4fbd6344d", //”Get Product Rate Plans” API will return rateplancharge ids
										startDate: {
											specificTriggerDate: "2024-03-20", //Today’s Date. ie. Run Date
											triggerEvent: "SpecificDate",
										},
									},
								],
								productRatePlanId: "8a8aa04d7f3a5d78017f42a4fb98344b", //”Get Product Rate Plans” API will return rateplan ids
							},
						],
						terms: {
							initialTerm: {
								period: 1,
								periodType: "Month", //”Get Product Rate Plans” API will return “billingPeriod” field, this will have value as Monthly/Annually
								startDate: "2024-03-20", //Today’s Date. ie. Run Date
								termType: "TERMED",
							},
							renewalSetting: "RENEW_WITH_SPECIFIC_TERM",
							renewalTerms: [
								{
									period: 1,
									periodType: "Month", //Today’s Date. ie. Run Date
								},
							],
						},
					},
					triggerDates: [
						{
							name: "ContractEffective",
							triggerDate: "2024-03-20", //Today’s Date. ie. Run Date
						},
						{
							name: "ServiceActivation",
							triggerDate: "2024-03-20", //Today’s Date. ie. Run Date
						},
						{
							name: "CustomerAcceptance",
							triggerDate: "2024-03-20", //Today’s Date. ie. Run Date
						},
					],
					type: "CreateSubscription",
				},
			],
		},
	],
};

export const cardTypes = [
	"Visa",
	"MasterCard",
	"AmericanExpress",
	"Discover",
	"JCB",
	"Diners",
	"CUP",
	"Maestro",
	"Electron",
	"AppleVisa",
	"AppleMasterCard",
	"AppleAmericanExpress",
	"AppleDiscover",
	"AppleJCB",
	"Elo",
	"Hipercard",
	"Naranja",
	"Nativa",
	"TarjetaShopping",
	"Cencosud",
	"Argencard",
	"Cabal",
	"Dankort",
	"MercadoLibre",
	"Mir",
	"CarteBancaire",
	"LocalCard",
	"GoogleVisa",
	"GoogleMasterCard",
	"GoogleAmericanExpress",
	"GoogleDiscover",
	"GoogleJCB",
	"RuPay",
];

export const billingCycleDayList = [
	{ name: "1st of the month", value: 1 },
	{ name: "2nd of the month", value: 2 },
	{ name: "3rd of the month", value: 3 },
	{ name: "4th of the month", value: 4 },
	{ name: "5th of the month", value: 5 },
	{ name: "6th of the month", value: 6 },
	{ name: "7th of the month", value: 7 },
	{ name: "8th of the month", value: 8 },
	{ name: "9th of the month", value: 9 },
	{ name: "10th of the month", value: 10 },
	{ name: "11th of the month", value: 11 },
	{ name: "12th of the month", value: 12 },
	{ name: "13th of the month", value: 13 },
	{ name: "14th of the month", value: 14 },
	{ name: "15th of the month", value: 15 },
	{ name: "16th of the month", value: 16 },
	{ name: "17th of the month", value: 17 },
	{ name: "18th of the month", value: 18 },
	{ name: "19th of the month", value: 19 },
	{ name: "20th of the month", value: 20 },
	{ name: "21st of the month", value: 21 },
	{ name: "22nd of the month", value: 22 },
	{ name: "23rd of the month", value: 23 },
	{ name: "24th of the month", value: 24 },
	{ name: "25th of the month", value: 25 },
	{ name: "26th of the month", value: 26 },
	{ name: "27th of the month", value: 27 },
	{ name: "28th of the month", value: 28 },
	{ name: "29th of the month", value: 29 },
	{ name: "30th of the month", value: 30 },
	{ name: "EOM(End of Month)", value: "EOM" },
];

export const paymentMethodList = ["CreditCard", "DebitCard"];
