import { useState } from "react";

export default function SelectDropdown({
	title,
	value,
	onChange,
	id,
	valueError,
	mapList,
}) {
	const [error, setError] = useState("");
	const handleChange = e => {
		onChange(e);
		setError("");
	};

	const handleBlur = e => {
		if (e.target.value === "") {
			setError(`${title} field is required`);
		}
	};

	console.log("mapList==", mapList);

	return (
		<div className="mt-5">
			<label
				htmlFor={id}
				className="block mb-2 text-sm text-gray-900 dark:text-black"
			>
				{title} *
			</label>
			<select
				id={id}
				value={value}
				onChange={handleChange}
				// onBlur={handleBlur}
				className="bg-[#f7faff] border border-gray-200 text-sm rounded-base block w-full p-2.5 outline-none"
			>
				<option value={""} className="text-black bg-white hover:bg-gray-200">
					Select {title}
				</option>
				{mapList && mapList.length > 0 ? (
					mapList.map((each, index) => (
						<option
							key={index}
							value={
								title === "State"
									? `${each.name}_${each.isoCode}_${each.countryCode}`
									: title === "Country"
									? `${each.name}_${each.isoCode}`
									: title === "City"
									? each.name
									: (title === "Card Type" || title === "Payment method"
											? each
											: title === "Billcycle method") && each?.value
							}
							className="text-black bg-white hover:bg-gray-200"
						>
							{title === "Card Type" || title === "Payment method"
								? each
								: each.name}
						</option>
					))
				) : (
					<option value="">No {title}s</option>
				)}
			</select>
			{(error || valueError) && (
				<div style={{ color: "red" }}>{error || valueError}</div>
			)}
		</div>
	);
}
