import { NavLink, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useState } from "react";
import { color } from "../assets/globalConst";
import CurrencyDropdown from "../components/currencyDropdown.js";

function HeaderHome() {
	const navigate = useNavigate();
	const [openMenu, setOpenMenu] = useState(false);
	const [openDropDown, setOpenDropDown] = useState(false);
	function handleMenu() {
		setOpenMenu(!openMenu);
	}
	function handleopenDropDown() {
		setOpenDropDown(!openDropDown);
	}
	return (
		<div className="">
			<header class="bg-white  border-b-2">
				<nav
					class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
					aria-label="Global"
				>
					<div class="flex lg:flex-1">
						{/* <a href="#" class="-m-1.5 p-1.5"> */}
						<span class="sr-only">Your Company</span>
						<img
							onClick={() => navigate("/")}
							class="h-8 w-auto"
							src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
							alt=""
						/>
						{/* </a> */}
					</div>
					<div class="flex lg:hidden">
						<button
							type="button"
							class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
							onClick={handleMenu}
						>
							<span class="sr-only">Open main menu</span>
							<svg
								class="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
								/>
							</svg>
						</button>
					</div>
					<div class="hidden lg:flex lg:gap-x-12">
						<ul className="flex items-center justify-center space-y-8   xxs:justify-around  lg:space-x-6 lg:space-y-0 xxs:hidden lg:flex ">
							<li className="smallText cursor-pointer">
								<NavLink
									to="/"
									style={({ isActive }) => ({
										color: isActive == true ? color : "",
										borderBottom: isActive == true ? `1px solid ${color} ` : "",
										// paddingBottom: isActive == true ? '12px' : '',
									})}
								>
									Home
								</NavLink>
							</li>
							{/* <li className="  smallText xxs:ml-1 lg:ml-0">
								<NavLink
									to="/"
									// style={({ isActive }) => ({
									//   color: isActive == true ? color : '',
									//   borderBottom: isActive == true ? `1px solid ${color} `: "",
									// })}
								>
									Solutions{" "}
								</NavLink>
							</li> */}
							<li className=" smallText xxs:ml-1 lg:ml-0">
								<NavLink
									to="/products"
									style={({ isActive }) => ({
										color: isActive == true ? color : "",
										borderBottom: isActive == true ? `1px solid ${color} ` : "",
									})}
								>
									Products
								</NavLink>
							</li>
							<li className="  smallText xxs:ml-1 lg:ml-0">
								<NavLink
									to="/plans"
									style={({ isActive }) => ({
										color: isActive == true ? color : "",
										borderBottom: isActive == true ? `1px solid ${color} ` : "",
									})}
								>
									Plans & Pricing
								</NavLink>
							</li>
							{/* <li className="  smallText xxs:ml-1 lg:ml-0 ">
								<Button title={"Get Started"} btnColor={true} />
							</li> */}
							<li className="  smallText xxs:ml-1 lg:ml-0">
								<Button title={"Log In"} btnColor={false} />
							</li>
							<li>
								<CurrencyDropdown />
							</li>
						</ul>
					</div>
					{/* <div class="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Log in <span aria-hidden="true">&rarr;</span></a>
          </div> */}
				</nav>
				{/* <!-- Mobile menu, show/hide based on menu open state. --> */}
				<div
					class={`${openMenu === true ? "block" : "hidden"}`}
					role="dialog"
					aria-modal="false"
				>
					{/* <!-- Background backdrop, show/hide based on slide-over state. --> */}
					<div class="absolute right-10 inset-0 z-10"></div>
					<div
						class={`inset-y-0 absolute right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10`}
					>
						<div class="flex items-center justify-between">
							<a href="#" class="-m-1.5 p-1.5">
								<span class="sr-only">Your Company</span>
								<img
									class="h-8 w-auto"
									src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
									alt=""
								/>
							</a>
							<button
								type="button"
								class="-m-2.5 rounded-md p-2.5 text-gray-700"
								onClick={handleMenu}
							>
								<span class="sr-only">Close menu</span>
								<svg
									class="h-6 w-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
						<div class="mt-6 flow-root">
							<div class="-my-6 divide-y divide-gray-500/10">
								<div class="space-y-2 py-6">
									<div class="-mx-3">
										{/* <!-- 'Product' sub-menu, show/hide based on menu state. --> */}
									</div>
									<p onClick={() => navigate("/")}>Home</p>
									{/* <p onClick={() => navigate("/")}>Solutions</p> */}
									<p onClick={() => navigate("/products")}>Products</p>
									<p onClick={() => navigate("/plans")}>Plans & Pricing</p>

									{/* <a href="#" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Features</a>
                  <a href="#" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Marketplace</a>
                  <a href="#" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Company</a> */}
								</div>
								<div class="py-6">
									{/* <div className="  smallText xxs:ml-1 lg:ml-0 ">
										<Button title={"Get Started"} btnColor={true} />
									</div> */}
									<div className="  smallText xxs:ml-1 lg:ml-0 my-3">
										<Button title={"Log In"} btnColor={false} />
									</div>
									<div>
										{/* <button
											onClick={handleopenDropDown}
											id="dropdownDefaultButton"
											data-dropdown-toggle="dropdown"
											class="text-white bg-blue-700 hover:bg-blue-800   font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
											type="button"
										>
											Dropdown button{" "}
											<svg
												class="w-2.5 h-2.5 ms-3"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 10 6"
											>
												<path
													stroke="currentColor"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="m1 1 4 4 4-4"
												/>
											</svg>
										</button>

										<div
											id="dropdown"
											className={`${
												openDropDown === true ? "block" : "hidden"
											} z-10 absolute  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}
										>
											<ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
												<li className="flex gap-2 p-2">
													<img
														className="h-5 w-8 "
														src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
													/>
													<span>Indian Rupee</span>
												</li>
												<li className="flex gap-2 p-2">
													<img
														className="h-5 w-8"
														src="https://cdn.britannica.com/73/4473-050-0D875725/Grand-Union-Flag-January-1-1776.jpg"
													/>
													<span>US Dollar</span>
												</li>
												<li className="flex gap-2 p-2">
													<img
														className="h-5 w-8"
														src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Flag_of_Europe.svg/255px-Flag_of_Europe.svg.png"
													/>
													<span>Euro</span>
												</li>
												<li className="flex gap-2 p-2">
													<img
														className="h-5 w-8"
														src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1280px-Flag_of_the_United_Kingdom.svg.png"
													/>
													<span>British Pound Sterling</span>
												</li>
											</ul>
										</div> */}
										<CurrencyDropdown />
									</div>
									{/* <a href="#" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a> */}
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* menu */}

				{/* end menu */}
			</header>
		</div>
	);
}
export default HeaderHome;
