export default function CheckBox({ title, onChange }) {
	const handleChange = e => {
		onChange(e);
	};
	return (
		<div class="flex items-center my-5">
			<input
				id="link-checkbox"
				onChange={e => handleChange(e)}
				type="checkbox"
				value=""
				class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
			/>
			<label
				for="link-checkbox"
				class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
			>
				{title}
			</label>
		</div>
	);
}
