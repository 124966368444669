// src/store.js
import { configureStore } from "@reduxjs/toolkit";

// Import your reducers here
import productPlanReducer from "../store/productSlice";

const store = configureStore({
	reducer: {
		// Add your reducers here
		productPlans: productPlanReducer,
	},
});

export default store;
