import axios from "axios";

// This for access token
export const getAccessToken = (clientId, clientSecret) => {
	return new Promise((resolve, reject) => {
		const urlencoded = new URLSearchParams();
		urlencoded.append("client_id", clientId);
		urlencoded.append("client_secret", clientSecret);
		urlencoded.append("grant_type", "client_credentials");

		axios
			.post("https://rest.test.zuora.com/oauth/token", urlencoded, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then(response => {
				resolve(response.data.access_token);
			})
			.catch(error => {
				reject(error);
			});
	});
};

export const getProductRatePlans = (product_id, clientId, clientSecret) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${process.env.REACT_APP_BACKENDENDPOINT}/product-rate-plans`, {
				params: {
					product_id: product_id,
					client_id: clientId,
					client_secret: clientSecret,
				},
			})
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
};

export const postOrder = (body, clientId, clientSecret) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${process.env.REACT_APP_BACKENDENDPOINT}/postOrder`, {
				body: {
					client_id: clientId,
					client_secret: clientSecret,
					payload: body,
				},
			})
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
};

export const getProducts = (clientId, clientSecret, query) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${process.env.REACT_APP_BACKENDENDPOINT}/getAllProduct`, {
				params: {
					client_id: clientId,
					client_secret: clientSecret,
					query,
				},
			})
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
};

export const getProductRatePlanById = (planId, clientId, clientSecret) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${process.env.REACT_APP_BACKENDENDPOINT}/getProductRatePlanById`, {
				params: {
					client_id: clientId,
					client_secret: clientSecret,
					planId,
				},
			})
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
};

export const getProductRatePlanChargeById = (
	planChargeId,
	clientId,
	clientSecret
) => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				`${process.env.REACT_APP_BACKENDENDPOINT}/getProductRatePlanChargeById`,
				{
					params: {
						client_id: clientId,
						client_secret: clientSecret,
						planChargeId,
					},
				}
			)
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
};
