import React, { useState, useEffect } from "react";
import ProductCard from "../utils/productCard";
import { getProducts } from "../../lib/api";
import { setAllProducts } from "../../store/productSlice";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import Loader from "../../common/Loader/loader";

const ProductList = () => {
	// const [allProducts, setProducts] = useState([]);
	const { allProducts } = useSelector(state => state.productPlans);
	const dispatch = useDispatch();
	const [pageSize, setPageSize] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [disablenext, setDisableNext] = useState(false);
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		const query = `page=${pageNumber}&pageSize=${pageSize}`;
		getCatLogProducts(
			"23bd6ca9-f77d-485f-ab17-1e7163b5bb90",
			"FLY0aeX0Vwar3J7ROL4htM61Nr1vLIRNAUregEb+4",
			query
		);
	}, [pageNumber]);

	const getCatLogProducts = (clientId, clientSecret, queryData) => {
		setIsLoading(true)
		getProducts(clientId, clientSecret, queryData)
			.then(res => {
				dispatch(setAllProducts(res?.data?.products));
				console.log("respose===", res);
				if (res?.data?.nextPage === undefined || res?.data?.nextPage === null) {
					setDisableNext(true);
				}
				setIsLoading(false)

			})
			.catch(err => {
				setIsLoading(false)

				console.log("rwjqwewe", err);
			});
	};

	// Handle next page
	const nextPage = () => {
		setPageNumber(prevPage => prevPage + 1);
	};

	// Handle previous page
	const prevPage = () => {
		if (pageNumber > 1) {
			if (disablenext) {
				setDisableNext(false);
			}

			setPageNumber(prevPage => prevPage - 1);
		}
	};

	console.log("padsasdsd asjdhasd", pageNumber);

	return (
		<div className="">
			<div className="setContainer grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 justify-center  ">
				{(!isLoading && allProducts?.length > 0) && (
					allProducts?.map(eachProd => (
						<div style={{ margin: 15 }} >
							<ProductCard
								title={eachProd?.name}
								planType={1}
								description={eachProd?.description}
								id={eachProd?.id}
							/>
						</div>

					))
				)}

				{(!isLoading && allProducts?.length === 0 && <p>No Products</p>)}
			</div>
			{isLoading && <div style={{ marginTop: 300, marginBottom: 300 }}> <Loader /> </div>}

			<div className="flex justify-end items-center mt-4">
				<button
					onClick={prevPage}
					disabled={pageNumber === 1}
					className="pagination-button"
				>
					&lt; Previous
				</button>
				<button
					onClick={nextPage}
					disabled={disablenext}
					className="pagination-button"
				>
					Next &gt;
				</button>
			</div>
		</div>
	);
};

export default ProductList;
