// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	currencyType: "USD",
	allProducts: [],
	productPlansAndPrice: [],
};

const counterSlice = createSlice({
	name: "productPlans",
	initialState,
	reducers: {
		setCurrencyType: (state, action) => {
			state.currencyType = action.payload;
		},
		setproductPlansAndPrice: (state, action) => {
			console.log("action===========", action);
			state.productPlansAndPrice = action.payload;
		},
		setAllProducts: (state, action) => {
			state.allProducts = action.payload;
		},
	},
});

export const { setCurrencyType, setproductPlansAndPrice, setAllProducts } =
	counterSlice.actions;

export default counterSlice.reducer;
