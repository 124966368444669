import { generateSignature, getAccessToken, getSignature } from "../lib/api.js";
import { useEffect } from "react";
import homeImg from '../assets/images/homeImg.png';

export default function HomePage() {
	return (
		<>
			<div className="bannerBg mb-10 ">
				<div className="grid md:grid-cols-2   place-content-center gap-10 setContainer  py-14">
					<img
						className="h-96  justify-self-center"
						src={homeImg}
						alt="banner image"
					/>

					<div className="place-self-center">
						<h3 className="text-white text-4xl ">
							Step into the Next Era of SaaS whith NexaCore
						</h3>
						<p className="text-white  text-xl mt-5">
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry. Lorem Ipsum has been the industry's of the printing and
							typesetting
						</p>
						<div className="flex gap-10 mt-5">
							<button className="trasperantBtn text-xl">Plans & Pricing</button>
							<button className="trasperantBtn text-xl">
								Plans from $ 29.00 per month
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="setContainer text-center mt-10">
				<h3 className="text-xl">CREATE.VISUALIZE.DEPLOY</h3>
				<p>
					An idea that takes thapart of a structure and turns them into a whole
					system. Practice is the frquent and continued contemplation of the
					mode of executing any given work, or of the mere operation
				</p>
			</div>
		</>
	);
}
