import { Route, Routes } from "react-router-dom";
import Common from "./Common";
import HomePage from "../pages/HomePage";
import BestPlans from "../pages/BestPlans";
import CheckOutForm from "../pages/CheckOutForm";
import Test from "../pages/Test";
import ProductList from "../components/products/index.js";

export default function HomeRoutes() {
	return (
		<>
			<Routes>
				<Route
					path="/"
					element={
						<Common>
							<HomePage />
						</Common>
					}
				/>
				<Route
					path="/products"
					element={
						<Common>
							<ProductList />
						</Common>
					}
				/>
				<Route
					path="/plans"
					element={
						<Common>
							<BestPlans />
						</Common>
					}
				/>
				<Route
					path="/checkout"
					element={
						<Common>
							<CheckOutForm />
						</Common>
					}
				/>
				<Route
					path="/test"
					element={
						<Common>
							<Test />
						</Common>
					}
				/>
			</Routes>
		</>
	);
}
