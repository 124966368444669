import { useNavigate } from "react-router-dom";
import { color } from "../../assets/globalConst";
import Button from "../Button";

export default function ProductCard({
	description,
	title,
	planType,
	price,
	salePrice,
	id,
}) {
	const navigate = useNavigate();
	function handleClickBtn() {
		navigate(`/plans?productId=${id}`);
	}
	return (
		<>
			<div style={{ minHeight : 350}}  class="w-full max-w-sm p-4  bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700 cardHover">
				<h5 class="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400 text-center">
					{title}
				</h5>
				<div class=" grid justify-center items-baseline text-gray-900 dark:text-white text-center">
					{/* {planType === 1 ? (
						<div className="flex justify-center gap-6">
							<span class="text-2xl font-bold tracking-tight text-gray-300 line-through ">
								{" "}
								$ {price}
							</span>

							<span class="text-3xl font-bold tracking-tight">
								{" "}
								$ {salePrice}
							</span>
						</div>
					) : (
						<div>
							<span class="text-3xl font-bold tracking-tight"> Custom</span>
						</div>
					)}

					<p className="text-center">
						per user / month paid annually minimum of 3 users
					</p> */}
					<div className="grid justify-center">
						<Button
							title={"Plans & Pricing"}
							btnColor={true}
							handleClickBtn={handleClickBtn}
						/>
					</div>
					{/* <h6 className={`text-${color} text-center`}>Try It</h6> */}
					<p>{description && description}</p>
				</div>
				<div className={` border border-[#5763c9] mt-5 divideStyle`}></div>
				{/* <ul role="list" class="space-y-5 my-7">
					<li class="flex items-center">
						<svg
							class="flex-shrink-0 w-4 h-4 text-green-500 dark:text-blue-500"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 20 20"
						>
							<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
						</svg>
						<span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
							Features/Descripti
						</span>
					</li>
					<li class="flex">
						<svg
							class="flex-shrink-0 w-4 h-4 text-green-500 dark:text-blue-500"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 20 20"
						>
							<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
						</svg>
						<span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
							ons can be hard
						</span>
					</li>
					<li class="flex">
						<svg
							class="flex-shrink-0 w-4 h-4 text-green-500 dark:text-blue-500"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 20 20"
						>
							<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
						</svg>
						<span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
							need control over
						</span>
					</li>

					<li class="flex">
						<svg
							class="flex-shrink-0 w-4 h-4 text-green-500 dark:text-blue-500"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 20 20"
						>
							<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
						</svg>
						<span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
							Box Canvas{" "}
							<span className="bg-green-500 text-white px-3 rounded-xl py-1 text-[8px]">
								NEW
							</span>
						</span>
					</li>
					<li class="flex">
						<svg
							class="flex-shrink-0 w-4 h-4 text-green-500 dark:text-blue-500"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 20 20"
						>
							<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
						</svg>
						<span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
							Buy it or Try it both options
						</span>
					</li>
					<li class="flex">
						<svg
							class="flex-shrink-0 w-4 h-4 text-green-500 dark:text-blue-500"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 20 20"
						>
							<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
						</svg>
						<span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
							Just for the Feel. It does nothing
						</span>
					</li>
				</ul> */}
				{/* <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-200 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center">Choose plan</button> */}
			</div>
		</>
	);
}
