import { useNavigate } from "react-router-dom";
import InputBox from "../components/utils/InputBox";
import CheckBox from "../components/utils/CheckBox";
import SelectDropdown from "../components/utils/select";
import Button from "../components/Button";
import { useCallback, useEffect, useRef, useState } from "react";
import { getCurentDate, validateForm } from "../lib/utils.js";
import {
	getProductRatePlanById,
	postOrder,
	getProductRatePlanChargeById,
} from "../lib/api.js";
import { useSelector } from "react-redux";
import PaymentSuccessPopup from "../common/payment/paymentsuccess.js";
import { Country, State, City } from "country-state-city";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
	billingCycleDayList,
	cardTypes,
	paymentMethodList,
} from "./sampleproductplan";
export default function CheckOutForm() {
	const queryParams = new URLSearchParams(window.location.search);
	const productId = queryParams.get("productId");

	const productRatePlanId = queryParams.get("productRatePlanId");
	const productRatePlanChargeId = queryParams.get("productRatePlanChargeId");

	const [billingDetails, setBillingDetails] = useState({
		fname: "",
		lname: "",
		companyName: "",
		email: "",
		cardNumber: "",
		cardExpairDate: "",
		postCode: "",
		securityKey: "",
		country: "",
		countryCode: "",
		state: "",
		stateCode: "",
		city: "",
		address1: "",
		address2: "",
		phoneNumber: "",
		cardType: "",
		billCycleDay: "",
		paymentMethod: "",
	});

	const [countryList, setCountryList] = useState(Country.getAllCountries());
	const [countryStates, setcountryStates] = useState([]);
	const [stateCitys, setstateCitys] = useState([]);
	const [productPlan, setProductPlan] = useState({});
	const planChargeData = useRef();
	const [productPlanCharge, setproductPlanCharge] = useState(null);
	const { currencyType } = useSelector(state => state.productPlans);
	const [priceObj, setPriceObj] = useState(null);
	const [isAccept, setisAccept] = useState(false);

	useEffect(() => {
		if (productRatePlanId && productRatePlanChargeId) {
			getproductPlan(productRatePlanId);
			getProductRatePlanCharge(productRatePlanChargeId);
		}
	}, [productRatePlanId, productRatePlanChargeId]);

	useEffect(() => {
		if (currencyType && productPlanCharge) {
			const filterPrice = productPlanCharge?.pricing?.find(
				each => each?.currency === currencyType
			);

			if (filterPrice) {
				setPriceObj(filterPrice);
			} else {
				setPriceObj({});
			}

			console.log("filterPrice===", filterPrice);
		}
	}, [currencyType]);

	const getProductRatePlanCharge = planChargeId => {
		getProductRatePlanChargeById(
			planChargeId,
			"23bd6ca9-f77d-485f-ab17-1e7163b5bb90",
			"FLY0aeX0Vwar3J7ROL4htM61Nr1vLIRNAUregEb+4"
		)
			.then(res => {
				console.log("resppppp", res);
				const filterPrice = res?.data?.pricing?.find(
					each => each?.currency === currencyType
				);

				console.log("filterPrice===", filterPrice);
				if (filterPrice) {
					setPriceObj(filterPrice);
				}
				// planChargeData.current = res?.data;
				setproductPlanCharge(res?.data);
			})
			.catch(err => {
				console.log("errrp", err);
			});
	};

	const getproductPlan = productPlanId => {
		getProductRatePlanById(
			productPlanId,
			"23bd6ca9-f77d-485f-ab17-1e7163b5bb90",
			"FLY0aeX0Vwar3J7ROL4htM61Nr1vLIRNAUregEb+4"
		)
			.then(res => {
				setProductPlan(res?.data);
				console.log("response===========", res);
			})
			.catch(err => {
				console.log("errorrrr", err);
			});
	};

	const [error, setError] = useState(null);
	const [paymentSuccess, setPaymentSuccess] = useState("");

	const handleChange = useCallback(event => {
		const clearError = { ...error };

		delete clearError[event.target.id];
		console.log("asd sdiusad asd", event.target.value);

		setError({ ...clearError });
		if (event.target.id === "country") {
			const splitContry = event.target.value.split("_");
			setBillingDetails(prevState => ({
				...prevState,
				country: splitContry[0],
				countryCode: splitContry[1],
			}));
			getStates(splitContry[1]);
		} else if (event.target.id === "state") {
			const splitContry = event.target.value.split("_");
			console.log("splitContry===", splitContry);
			setBillingDetails(prevState => ({
				...prevState,
				state: splitContry[0],
				stateCode: splitContry[1],
			}));
			citys(splitContry[2], splitContry[1]);
		} else {
			setBillingDetails(prevState => ({
				...prevState,
				[event.target.id]: event.target.value,
			}));
		}
	}, []);

	const getStates = countryCode => {
		console.log(
			"State.getStatesOfCountry(countryCode)==",
			State.getStatesOfCountry(countryCode)
		);
		setstateCitys([]);

		setcountryStates(State.getStatesOfCountry(countryCode));
	};

	const citys = (countryCode, stateCode) => {
		console.log(
			"City.getCitiesOfState(countryCode, stateCode)==",
			countryCode,
			stateCode,
			City.getCitiesOfState(countryCode, stateCode)
		);
		setstateCitys(City.getCitiesOfState(countryCode, stateCode));
	};

	function separateMonthAndYear(dateStr) {
		const [month, year] = dateStr.split("/");
		const currentYear = new Date().getFullYear();
		const currentCentury = Math.floor(currentYear / 100); // Get the current century
		const getYear = `${currentCentury}${year}`;
		return { month, getYear };
	}

	const handleSubmit = () => {
		const error = validateForm(billingDetails);
		setError(error);
		console.log("dfasdafad", error);

		if (error && Object.keys(error)?.length === 0) {
			if (isAccept) {
				const body = {
					description: productPlan?.description,
					orderDate: getCurentDate()?.formattedDate, //Today’s Date
					newAccount: {
						name: `${billingDetails?.fname?.trim()} ${billingDetails?.lname?.trim()}`, //First Name + Last Name from the CheckOut Page
						currency: currencyType, //Currency Mentioned at the Top Right Currency Drop down on Pricing Page
						billCycleDay: billingDetails?.billCycleDay, //Today’s Date, only DD part e.g 23rd March 2024 is the date, we will only consider 23
						billToContact: {
							firstName: billingDetails?.fname?.trim(), //First Name from the CheckOut Page
							lastName: billingDetails?.lname?.trim(), //Last Name from the CheckOut Page
							address1: billingDetails?.address1,
							address2: billingDetails?.address2,
							city: billingDetails?.city,
							country: billingDetails?.country, //Country from the CheckOut Page
							mobilePhone: billingDetails?.phoneNumber,
							state: billingDetails?.state,
							postalCode: billingDetails?.postCode, //Postal Code from the CheckOut Page
						},
						paymentGateway: "Sample", //Payment Gateway Field from the ADMIN Page
						paymentMethod: {
							type: billingDetails?.paymentMethod,
							cardHolderInfo: {
								cardHolderName: `${billingDetails?.fname} ${billingDetails?.lname}`, //First Name + Last Name from the CheckOut Page
							},
							cardNumber: billingDetails?.cardNumber, //Credit Card from the CheckOut Page
							cardType: billingDetails?.cardType,
							expirationMonth: separateMonthAndYear(
								billingDetails?.cardExpairDate
							)?.month, // MM out of “MM/YY” from the CheckOut Page
							expirationYear: separateMonthAndYear(
								billingDetails?.cardExpairDate
							)?.getYear, //YYYY out of “MM/YY” from the CheckOut Page
							securityCode: billingDetails?.securityKey, //Security Code from the CheckOut Page
						},
						soldToContact: {
							firstName: billingDetails?.fname?.trim(), //First Name from the CheckOut Page
							lastName: billingDetails?.lname?.trim(), //Last Name from the CheckOut Page
							address1: billingDetails?.address1,
							address2: billingDetails?.address2,
							city: billingDetails?.city,
							country: billingDetails?.country, //Country from the CheckOut Page
							mobilePhone: billingDetails?.phoneNumber,
							state: billingDetails?.state,
							postalCode: billingDetails?.postCode, //Postal Code from the CheckOut Page
						},
					},
					processingOptions: {
						billingOptions: {
							generateDraftInvoice: "false",
							targetDate: getCurentDate()?.formattedDate, //Today’s Date. ie. Run Date
						},
						collectPayment: false,
						runBilling: false,
					},
					subscriptions: [
						{
							orderActions: [
								{
									createSubscription: {
										subscribeToRatePlans: [
											{
												chargeOverrides: [
													{
														productRatePlanChargeId: productRatePlanChargeId, //”Get Product Rate Plans” API will return rateplancharge ids
														startDate: {
															specificTriggerDate:
																getCurentDate()?.formattedDate, //Today’s Date. ie. Run Date
															triggerEvent: "SpecificDate",
														},
													},
												],
												productRatePlanId: productRatePlanId, //”Get Product Rate Plans” API will return rateplan ids
											},
										],
										terms: {
											initialTerm: {
												period: 1,
												periodType: "Month", //”Get Product Rate Plans” API will return “billingPeriod” field, this will have value as Monthly/Annually
												startDate: getCurentDate()?.formattedDate, //Today’s Date. ie. Run Date
												termType: "TERMED",
											},
											renewalSetting: "RENEW_WITH_SPECIFIC_TERM",
											renewalTerms: [
												{
													period: 1,
													periodType: "Month", //Today’s Date. ie. Run Date
												},
											],
										},
									},
									triggerDates: [
										{
											name: "ContractEffective",
											triggerDate: getCurentDate()?.formattedDate, //Today’s Date. ie. Run Date
										},
										{
											name: "ServiceActivation",
											triggerDate: getCurentDate()?.formattedDate, //Today’s Date. ie. Run Date
										},
										{
											name: "CustomerAcceptance",
											triggerDate: getCurentDate()?.formattedDate, //Today’s Date. ie. Run Date
										},
									],
									type: "CreateSubscription",
								},
							],
						},
					],
				};

				console.log("body===", JSON.stringify(body));

				postOrder(
					body,
					"23bd6ca9-f77d-485f-ab17-1e7163b5bb90",
					"FLY0aeX0Vwar3J7ROL4htM61Nr1vLIRNAUregEb+4"
				)
					.then(res => {
						console.log("rsponse====", res);
						if (res?.data?.success) {
							setPaymentSuccess("sucess");
							setTimeout(() => {
								setPaymentSuccess("");
								setBillingDetails({
									fname: "",
									lname: "",
									companyName: "",
									email: "",
									cardNumber: "",
									cardExpairDate: "",
									postCode: "",
									securityKey: "",
									country: "",
									countryCode: "",
									state: "",
									stateCode: "",
									city: "",
									address1: "",
									address2: "",
									phoneNumber: "",
									cardType: "",
								});
								navigate(`/plans?productId=${productId}`);
							}, 5000);
						} else {
							setPaymentSuccess("fail");
						}
					})
					.catch(err => {
						console.log("err==", err);
					});
			} else {
				setError({
					isAccept: "You must agree to the terms and conditions to proceed.",
				});
			}
		}
	};

	const handleChangePhone = phone => {
		setBillingDetails(prevState => ({
			...prevState,
			phoneNumber: `+${phone}`,
		}));
	};
	const handleExpiryChange = e => {
		const input = e.target.value;

		// Remove non-numeric characters and limit to 4 characters (MMYY)
		const formattedInput = input.replace(/\D/g, "").slice(0, 4);

		// Format the input as MM/YY
		let formattedExpiry = "";
		if (formattedInput.length >= 2) {
			formattedExpiry =
				formattedInput.slice(0, 2) + "/" + formattedInput.slice(2);
		} else {
			formattedExpiry = formattedInput;
		}

		// Update state with the formatted expiry date
		setBillingDetails(prevState => ({
			...prevState,
			cardExpairDate: formattedExpiry,
		}));
	};

	const navigate = useNavigate();

	const handleChangeCheckbox = e => {
		console.log("skdgsdh sss");
		setisAccept(!isAccept);
	};

	console.log("billingDetails===", isAccept);
	return (
		<div className="my-10">
			<div className="setContainer grid lg:grid-cols-3 gap-10 ">
				<div className="col-span-2 borderStyle">
					<h3 className="header ">Add Billing Details</h3>

					<div className="grid md:grid-cols-2 gap-5 ">
						<InputBox
							title={"First Name"}
							type={"text"}
							value={billingDetails?.fname}
							onChange={handleChange}
							valueError={error?.fname}
							id={"fname"}
						/>
						<InputBox
							title={"Last Name"}
							type={"text"}
							value={billingDetails?.lname}
							valueError={error?.lname}
							onChange={handleChange}
							id={"lname"}
						/>
					</div>
					<InputBox
						title={"Company"}
						type={"text"}
						value={billingDetails?.companyName}
						valueError={error?.companyName}
						onChange={handleChange}
						id={"companyName"}
					/>
					<InputBox
						title={"Email"}
						type={"email"}
						value={billingDetails?.email}
						valueError={error?.email}
						onChange={handleChange}
						id={"email"}
					/>
					{/* <InputBox
						title={"Phone Number"}
						type={"text"}
						value={billingDetails?.email}
						valueError={error?.email}
						onChange={handleChange}
						id={"email"}
					/> */}
					<div className="mt-5">
						<label
							for="default-input"
							class="block mb-2 text-sm   text-gray-900 dark:text-black"
						>
							Phone number*
						</label>
						<PhoneInput
							country={"us"}
							value={""}
							inputStyle={{
								width: "100%",
								backgroundColor: "rgb(247 250 255)",
								border: "1px solid #e5e7eb",
								borderWidth: "1px",
								borderRadius: "0px",
							}}
							onChange={phone => handleChangePhone(phone)}
						/>
						{error?.phoneNumber && (
							<div style={{ color: "red" }}>{error?.phoneNumber}</div>
						)}
					</div>
					<div className="grid md:grid-cols-2 gap-5 ">
						{/* <InputBox
							title={"Country"}
							type={"text"}
							value={billingDetails?.country}
							onChange={handleChange}
							id={"country"}
						/> */}
						<SelectDropdown
							title={"Billcycle method"}
							value={`${billingDetails?.billCycleDay}`}
							onChange={handleChange}
							valueError={error?.billCycleDay}
							mapList={billingCycleDayList}
							id={"billCycleDay"}
						/>
						<SelectDropdown
							title={"Payment method"}
							value={`${billingDetails?.paymentMethod}`}
							onChange={handleChange}
							valueError={error?.paymentMethod}
							mapList={paymentMethodList}
							id={"paymentMethod"}
						/>
					</div>

					<div className="grid md:grid-cols-2 gap-5 ">
						<InputBox
							title={"Card Number"}
							type={"text"}
							value={billingDetails?.cardNumber}
							valueError={error?.cardNumber}
							onChange={handleChange}
							id={"cardNumber"}
						/>
						<SelectDropdown
							title={"Card Type"}
							value={billingDetails?.cardType}
							onChange={handleChange}
							valueError={error?.cardType}
							mapList={cardTypes}
							id={"cardType"}
						/>
					</div>

					<div className="grid md:grid-cols-2 gap-5 ">
						<InputBox
							title={"MM/YY"}
							type={"text"}
							value={billingDetails?.cardExpairDate}
							valueError={error?.cardExpairDate}
							onChange={handleExpiryChange}
							id={"cardExpairDate"}
						/>
						<InputBox
							title={"Security Code"}
							type={"text"}
							value={billingDetails?.securityKey}
							valueError={error?.securityKey}
							onChange={handleChange}
							id={"securityKey"}
						/>
					</div>
					<div className="grid md:grid-cols-2 gap-5 ">
						{/* <InputBox
							title={"Country"}
							type={"text"}
							value={billingDetails?.country}
							onChange={handleChange}
							id={"country"}
						/> */}
						<SelectDropdown
							title={"Country"}
							value={`${billingDetails?.country}_${billingDetails?.countryCode}`}
							onChange={handleChange}
							valueError={error?.country}
							mapList={countryList}
							id={"country"}
						/>
						<SelectDropdown
							title={"State"}
							value={`${billingDetails?.state}_${billingDetails?.stateCode}_${billingDetails?.countryCode}`}
							onChange={handleChange}
							valueError={error?.state}
							mapList={countryStates}
							id={"state"}
						/>
					</div>
					<div className="grid md:grid-cols-2 gap-5 ">
						<SelectDropdown
							title={"City"}
							value={billingDetails?.city}
							onChange={handleChange}
							valueError={error?.city}
							mapList={stateCitys}
							id={"city"}
						/>
						<InputBox
							title={"Post Code"}
							type={"text"}
							value={billingDetails?.postCode}
							valueError={error?.postCode}
							onChange={handleChange}
							id={"postCode"}
						/>
					</div>
					<InputBox
						title={"Address1"}
						type={"text"}
						value={billingDetails?.address1}
						valueError={error?.address1}
						onChange={handleChange}
						id={"address1"}
					/>
					<InputBox
						title={"Address2"}
						type={"text"}
						value={billingDetails?.address2}
						valueError={error?.address2}
						onChange={handleChange}
						id={"address2"}
					/>
					<CheckBox
						onChange={e => handleChangeCheckbox(e)}
						title={"I agree with the terms and conditions."}
					/>
					{isAccept}
					{error?.isAccept && (
						<div style={{ color: "red" }}>{error?.isAccept}</div>
					)}
					<Button
						title={"Purchse"}
						btnColor={true}
						handleClickBtn={handleSubmit}
					/>
				</div>

				<div className="col-span-1">
					<p className="gray">Order Summary</p>
					{/* <h3 className="header">Standard</h3> */}

					<p
						className="text-blue-500 cursor-pointer"
						onClick={() => navigate(`/plans?productId=${productId}`)}
					>
						Change
					</p>
					{/* <div>
						<label class="inline-flex items-center cursor-pointer my-10">
							<span class="ms-3 text-sm font-medium gray dark:text-gray-300 mr-3">
								Monthly
							</span>

							<input type="checkbox" value="" class="sr-only peer" />
							<div class="relative w-11 h-6 bg-gray-200 rounded-full peer  dark:peer-focus:ring-#2ebd81 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2ebd81]"></div>
							<span class="ms-3 text-sm font-medium  black dark:text-gray-300">
								Annually <br /> (save 20%)
							</span>
						</label>
					</div> */}
					<div className="my-10">
						<span>
							Promo Code {/* <span className="px-3 bg-white border rounded"> */}
							<input
								type="text"
								className="outline-none inputTag"
								// class="bg-[#f7faff] border border-gray-200  text-sm rounded-base block w-full p-2.5  outline-none    "
								placeholder="Enter promo code"
							/>
						</span>
						{/* </span> */}
					</div>

					<div className="bg-white border p-5">
						{/* <h6 className="gray">RECURRING MONTHLY COST</h6> */}
						<div className="border-b flex justify-between items-center my-5">
							<div>
								<h3>{productPlanCharge?.name}</h3>
								<p className="text-gray-600">
									${priceObj?.price ? priceObj?.price : 0} per month
								</p>
							</div>
							<div className="font-bold">
								${priceObj?.price ? priceObj?.price : 0}/mo
							</div>
						</div>
						<div className="flex justify-between font-bold">
							<h3>Today's Total</h3>
							<h3>${priceObj?.price ? priceObj?.price : 0}</h3>
						</div>
						{priceObj && Object.keys(priceObj)?.length === 0 && (
							<div style={{ color: "red" }}>
								There are no price details for the selected currency.
							</div>
						)}
					</div>
				</div>
			</div>
			{paymentSuccess === "sucess" && (
				<PaymentSuccessPopup
					handleClose={() => {
						setPaymentSuccess("");
						navigate(`/plans?productId=${productId}`);
					}}
					paymetfailOrSucess={paymentSuccess}
				/>
			)}
			{paymentSuccess === "fail" && (
				<PaymentSuccessPopup
					handleClose={() => setPaymentSuccess("")}
					paymetfailOrSucess={paymentSuccess}
				/>
			)}
		</div>
	);
}
