import { useState } from "react";

export default function InputBox({
	title,
	type,
	value,
	onChange,
	id,
	valueError,
}) {
	const [error, setError] = useState("");
	const handleChange = e => {
		onChange(e);
		setError("");
	};

	const handleBlur = e => {
		if (e.target.value === "") {
			setError(`${title} filed is required`);
		}
	};

	return (
		<>
			<div className="mt-5">
				<label
					for="default-input"
					class="block mb-2 text-sm   text-gray-900 dark:text-black"
				>
					{title} *
				</label>
				<input
					type={type}
					value={value}
					onChange={handleChange}
					onBlur={handleBlur}
					pattern="\d{2}/\d{2}"
					id={id}
					class="bg-[#f7faff] border border-gray-200  text-sm rounded-base block w-full p-2.5  outline-none    "
				/>
				{(error || valueError) && (
					<div style={{ color: "red" }}>{error || valueError}</div>
				)}
			</div>
		</>
	);
}
