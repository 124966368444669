import PlanCard from "../components/utils/PlanCard";
import { useEffect, useRef, useState } from "react";
import { getAccessToken, getProductRatePlans } from "../lib/api.js";
import { productPlan } from "./sampleproductplan";
import Loader from "../common/Loader/loader.js";
import { useSelector, useDispatch } from "react-redux";
import { setproductPlansAndPrice } from "../store/productSlice";
import { useNavigate } from "react-router-dom";

export default function BestPlans() {
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(window.location.search);
	const productId = queryParams.get("productId");
	console.log("productId=====", productId);
	const [planType, setPlanType] = useState("business");
	const [productDetails, setproductDetails] = useState(productPlan);
	const [planProducts, setplanProducts] = useState([]);
	const [plan, setPlan] = useState("All");
	const [loader, setLoader] = useState(false);
	const productRefData = useRef(null);
	const { currencyType } = useSelector(state => state.productPlans);
	const dispatch = useDispatch();

	console.log("productPlans====", currencyType);

	// function handlePlanType(type) {
	// 	setPlanType(type);
	// 	const filterProduct = productRefData?.current?.filter(each => {
	// 		if (
	// 			type === "business" &&
	// 			each.description === "B" &&
	// 			each.productRatePlanCharges[0].billingPeriod === plan
	// 		) {
	// 			return each;
	// 		} else if (
	// 			type === "Individual" &&
	// 			each.description === "IT" &&
	// 			each.productRatePlanCharges[0].billingPeriod === plan
	// 		) {
	// 			return each;
	// 		}
	// 	});
	// 	setplanProducts(filterProduct);
	// 	console.log("filterProduct===", filterProduct);
	// }

	useEffect(() => {
		if (productId) {
			getProductDetails(
				productId,
				"23bd6ca9-f77d-485f-ab17-1e7163b5bb90",
				"FLY0aeX0Vwar3J7ROL4htM61Nr1vLIRNAUregEb+4"
			);
		}
	}, [productId]);

	// "8a8aa2e37e999eef017e9c600c786004",
	// 		"23bd6ca9-f77d-485f-ab17-1e7163b5bb90",
	// 		"FLY0aeX0Vwar3J7ROL4htM61Nr1vLIRNAUregEb+4"

	useEffect(() => {
		if (productRefData.current) {
			if (plan === "All") {
				let allCharges = [];

				productRefData.current.forEach(product => {
					console.log("product==", product);
					if (
						product.productRatePlanCharges &&
						product.productRatePlanCharges.length > 0
					) {
						product.productRatePlanCharges.forEach(charge => {
							// Create a new object with existing properties of `charge` plus `productRatePlanId`
							const newCharge = {
								...charge,
								productRatePlanId: product.id,
							};
							console.log("newCharge===", newCharge);
							allCharges.push(newCharge); // Push the modified charge object to allCharges array
						});
					}
				});
				console.log("allCharges===", allCharges);
				setplanProducts(allCharges);
			} else if (plan !== "All") {
				let allCharges = [];
				productRefData.current.forEach(plan => {
					if (
						plan.productRatePlanCharges &&
						plan.productRatePlanCharges.length > 0
					) {
						allCharges = allCharges.concat(plan.productRatePlanCharges);
					}
				});
				const filterProduct = allCharges?.filter(each => {
					if (each?.billingPeriod === plan) {
						return each;
					}
				});
				setplanProducts(filterProduct);
			}
			setLoader(false);
		}
	}, [productRefData.current, plan]);

	const getProductDetails = (projectId, clientId, clientSecret) => {
		setLoader(true);
		getProductRatePlans(projectId, clientId, clientSecret)
			.then(res => {
				console.log("respone=======", res);
				productRefData.current = res?.data?.productRatePlans;
				dispatch(setproductPlansAndPrice(res?.data?.productRatePlans));
				// setLoader(false);
			})
			.catch(err => {
				console.log("error=", err);
				setLoader(false);
			});
	};

	const handleChangePlan = eachPlan => {
		console.log("plan================", eachPlan);
		// if (eachPlan === "Month") {
		// 	const filterProduct = productRefData?.current?.filter(each => {
		// 		if (each.productRatePlanCharges[0].billingPeriod === "Annual") {
		// 			return each;
		// 		} else if (each.productRatePlanCharges[0].billingPeriod === "Annual") {
		// 			return each;
		// 		} else if (each.productRatePlanCharges[0].billingPeriod === "Annual") {
		// 			return each;
		// 		} else if (each.productRatePlanCharges[0].billingPeriod === "Annual") {
		// 			return each;
		// 		} else if (each.productRatePlanCharges[0].billingPeriod === "Annual") {
		// 			return each;
		// 		}
		// 		// if (
		// 		// 	// planType === "business" &&
		// 		// 	// each.description === "B" &&
		// 		// 	each.productRatePlanCharges[0].billingPeriod === "Annual"
		// 		// ) {
		// 		// 	return each;
		// 		// } else if (
		// 		// 	// planType === "Individual" &&
		// 		// 	// each.description === "IT" &&
		// 		// 	each.productRatePlanCharges[0].billingPeriod === "Annual"
		// 		// ) {
		// 		// 	return each;
		// 		// }
		// 	});

		// 	console.log("filterProduct===", filterProduct);
		// 	setplanProducts(filterProduct);
		// } else if (eachPlan === "Annual") {
		// 	const filterProduct = productRefData?.current?.filter(each => {
		// 		if (
		// 			// planType === "business" &&
		// 			// each.description === "B" &&
		// 			each.productRatePlanCharges[0].billingPeriod === "Month"
		// 		) {
		// 			return each;
		// 		} else if (
		// 			// planType === "Individual" &&
		// 			// each.description === "IT" &&
		// 			each.productRatePlanCharges[0].billingPeriod === "Month"
		// 		) {
		// 			return each;
		// 		}
		// 	});

		// 	console.log("filterProduct===", filterProduct);
		// 	setplanProducts(filterProduct);
		// }

		setPlan(eachPlan);
	};

	console.log("res?.data?.productRatePlans=", planProducts);

	return (
		<>
			<h3 className="text-3xl font-semibold text-center my-10">
				Choose the best plan for your business
			</h3>
			<div className="flex justify-center gap-10 cursor-pointer my-10">
				{/* <div className="flex">
					<p
						onClick={() => handlePlanType("Individual")}
						className={`${planType === "Individual" ? "btnColor" : "p-2"}`}
					>
						Individuals and Teams
					</p>
					<p
						onClick={() => handlePlanType("business")}
						className={`${planType === "business" ? "btnColor" : "p-2"}`}
					>
						Business Plans
					</p>
				</div> */}
				<div>
					<label class="inline-flex items-center cursor-pointer mt-2">
						<span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 mr-3">
							Monthly
						</span>

						<input
							type="checkbox"
							value=""
							checked={plan === "Month" ? false : plan === "Quarter" && true}
							class="sr-only peer"
							onChange={() =>
								handleChangePlan(
									plan === "Month" ||
										plan === "All" ||
										plan === "Semiannual" ||
										plan === "Annual"
										? "Quarter"
										: "Month"
								)
							}
						/>
						<div class="relative w-11 h-6 bg-gray-200 rounded-full peer  dark:peer-focus:ring-#2ebd81 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2ebd81]"></div>
						<span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
							Quarterly
							{/* <br /> (save 20%) */}
						</span>
					</label>
				</div>
				<div>
					<label class="inline-flex items-center cursor-pointer mt-2">
						<span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 mr-3">
							Semi-Annual
						</span>

						<input
							type="checkbox"
							value=""
							checked={
								plan === "Semiannual" ? false : plan === "Annual" && true
							}
							class="sr-only peer"
							onChange={() =>
								handleChangePlan(
									plan === "Semiannual" ||
										plan === "Month" ||
										plan === "Quarter" ||
										plan === "All"
										? "Annual"
										: "Semiannual"
								)
							}
						/>
						<div class="relative w-11 h-6 bg-gray-200 rounded-full peer  dark:peer-focus:ring-#2ebd81 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2ebd81]"></div>
						<span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
							Annual
							{/* <br /> (save 20%) */}
						</span>
					</label>
				</div>
				<div>
					<label class="inline-flex items-center cursor-pointer mt-2">
						<input
							type="checkbox"
							value=""
							checked={plan === "All" ? true : false}
							class="sr-only peer"
							onChange={() => handleChangePlan("All")}
						/>
						<div class="relative w-11 h-6 bg-gray-200 rounded-full peer  dark:peer-focus:ring-#2ebd81 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2ebd81]"></div>
						<span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
							All
						</span>
					</label>
				</div>
			</div>

			{planProducts && planProducts?.length > 0 && !loader ? (
				<div className="setContainer grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 justify-center  ">
					{planProducts &&
						planProducts?.length > 0 &&
						planProducts?.map(each => {
							const filter = each?.pricing?.find(
								eachPrice => eachPrice?.currency === currencyType
							);

							if (filter) {
								return (
									<div style={{margin: 15}}>
										<PlanCard
											title={each.name}
											price={filter && filter?.price}
											salePrice={filter && filter?.price}
											planType={1}
											description={each?.description}
											id={each.productRatePlanId}
											planId={each?.id}
											productId={productId}
										/>
									</div>

								);
							} else {
								return null;
							}
						})}
					{/* <PlanCard
					title={"Business Plus"}
					price={"2635"}
					salePrice={"1,980"}
					planType={1}
					description={"Advanced content management and data protection"}
				/>
				<PlanCard
					title={"Enterprise"}
					price={"3,635"}
					salePrice={"2,730"}
					planType={1}
					description={"Advanced content management and data protection"}
				/>
				<PlanCard
					title={"Enterprise Plus"}
					price={"1,545"}
					salePrice={"1,160"}
					planType={2}
					description={"Advanced content management and data protection"}
				/> */}
				</div>
			) : loader ? (
				<Loader />
			) : (
				<div className="flex justify-center gap-10 my-10">
					{productId ? (
						<p className="text-gray-500">No products available</p>
					) : (
						<p className="text-gray-500">
							Please select a product from the list.{" "}
							<button
								onClick={() => navigate("/products")}
								className="text-blue-500 underline hover:text-blue-700"
							>
								Click here
							</button>
						</p>
					)}
				</div>
			)}
		</>
	);
}
