export default function Button({ title, btnColor, handleClickBtn }) {
	return (
		<>
			<button
				type="button"
				onClick={handleClickBtn}
				className={`${btnColor === true ? "btnColor" : "btnColor2"}`}
			>
				{title}
			</button>

			{/* <button className={`${btnColor === true ? 'btnColor' :'btnColor2'}`}>{title}</button> */}
		</>
	);
}
