import React, { useState } from "react";
import "./paymentsuccess.css"; // Make sure to create this file

function PaymentSuccessPopup(props) {
	const togglePopup = () => {
		props?.handleClose();
	};

	return (
		<div>
			<div className="popup-overlay">
				<div className="popup">
					<div className="popup-header">
						<button className="close-popup-btn" onClick={togglePopup}>
							×
						</button>
					</div>
					{props?.paymetfailOrSucess === "sucess" && (
						<div className="popup-body">
							<div className="tick-icon">✔</div>
							<h2 className="heading">Payment Successful</h2>
							<p>Your payment has been processed successfully!</p>
						</div>
					)}
					{props?.paymetfailOrSucess === "fail" && (
						<div className="popup-body">
							<h2 className="headingFail">Payment Failed</h2>
							<p>
								Unfortunately, your payment could not be processed. Please try
								again.
							</p>
						</div>
					)}
					<div className="popup-footer">
						<button className="close-popup-btn" onClick={togglePopup}>
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PaymentSuccessPopup;
