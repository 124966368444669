import { useEffect, useState } from "react"

export default function Test() {



    let obj = {
        "sai" :{
            id:1,
            name:'teja'
        },
        "koti" :{
            id:2,
            name:'aaa'
        },
    }
    const [name, setname] = useState([])
    const [checked, setchecked] = useState(false)
    const [checked2, setchecked2] = useState(false)

    function select(val, type) {

        if (type == 1 && checked == false) {
            setchecked(!checked)
            setname(pre => [...pre, val])
        }
        if (type == 1 && checked == true) {
            setchecked(false)
            setname(name.filter((e) => e !== val))

        }
        if (type == 2 && checked2 == false) {
            setchecked2(true)
            setname(pre => [...pre, val])
        }
        if (type == 2 && checked2 == true) {
            setchecked2(false)
            setname(name.filter((e) => e !== val))

        }
    }

    function changeHandler (val){
console.log(val);
    }


    const dataArray = Object.entries(obj).map(([key, value]) => {
        return {
          [key]: value
        };
      });  
      
      console.log(dataArray.map((e) => e));
      
      
      return (
        <>

            <input type="checkbox" value={'sai tejaa'} checked={checked} onChange={(e) => select(e.target.value, 1)} /> sai teja <br />
            <input type="checkbox" value={'Koti'} checked={checked2} onChange={(e) => select(e.target.value, 2)} />koti <br />

            <textarea value={name}></textarea>


<select value={0} onChange={(e) => changeHandler(e.target.value)}>
<option>select</option>
<option value={1}>select</option>
<option>select</option>
    {/* {dataArray.map((e,ind,arr) => <option>{e.sai}</option>)} */}
     
</select>

          
        </>
    )
}