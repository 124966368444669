import logo from './logo.svg';
import './App.css';
import { Routes } from 'react-router-dom';
import HomeRoutes from './routes/HomeRoutes'
function App() {
  return (
    <>
      <HomeRoutes />
   
    </>
  );
}

export default App;
