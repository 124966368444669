import FooterHome from "../pages/FooterHome";
import HeaderHome from "../pages/HeaderHome";

export default function Common({ children }) {
	return (
		<>
			<HeaderHome />
			{children}
			<FooterHome />
		</>
	);
}
